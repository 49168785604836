:root {
  --text_color: #0F191B;
  --day_size: 90%;
  --day_size_small: 80%;
  --day_size_smallest: 50%;
}

.container {
  width: 100%;
}

.container .react-datepicker {
    border: unset;
    width: 350px;
    font-family: 'Montserrat' !important;
    color: var(--text_color);
}

.react-datepicker .loading .spinner {
      display: block;
 } 

.container .react-datepicker__month-container {
    width: 100%;
}

.container .react-datepicker__month .react-datepicker__week {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
}
.container .react-datepicker__day {
    display: flex;
    justify-self: center;
    width: var(--day_size);
    height: var(--day_size);
    align-items: center;
    justify-content: center;
    font-size: 1rem;
  }
  @media only screen and (max-width: 502px) {
    .container .react-datepicker__day {
        font-size: 0.75rem;
        width: var(--day_size_smallest);
        height: var(--day_size_smallest);
    }
  }

  @media only screen and (max-width: 1220px) and (min-width: 993px) {
    .container .react-datepicker__day {
        font-size: 0.875rem;
        width: var(--day_size_small);
        height: var(--day_size_small);
    }
  }

  .container .react-datepicker__day .react-datepicker__day--highlighted {
    color: inherit;
    background-color: var(--highlighted);
    border-radius: 8px;
  }

  .container .react-datepicker__day .react-datepicker__day--selected {
    background-color: var(--selected);
    border-radius: 8px;
    font-weight: 700;
    color: white;
  }

  .datePicker_header {
    position: relative;
    max-width: 100%;
  }


.container .react-datepicker__month-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
}
.container .react-datepicker__month-text {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  font-weight: 700;
  font-size: 1rem;
}

.customCalendar {
  background-color: var(--bg);
}

.customCalendar .react-datepicker__header {
  background-color: red;
  border: unset;      
}

.customCalendar .react-datepicker__day-names {
  margin: 0 0.4rem;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.customCalendar .react-datepicker__day-names {
  margin: 0 0.4rem;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.customCalendar .react-datepicker__day-names .react-datepicker__day-name {
  justify-self: center;
  text-align: center;
  font-size: 0.75rem;
  width: var(--day_size);
  height: var(--day_size);
}

@media only screen and (max-width: 502px) {
.customCalendar .react-datepicker__day-names .react-datepicker__day-name {
  font-size: 0.65rem;
  width: var(--day_size_smallest);
  height: var(--day_size_smallest);
  }
}

@media only screen and (max-width: 1220px) and (min-width: 993px) {
.customCalendar .react-datepicker__day-names .react-datepicker__day-name {
      width: var(--day_size_small);
      height: var(--day_size_small);
  }
}
/* Header styles */
  
.currentMonth {
  margin: 5px 0 10px 0;
  font-weight: 400;
  font-size: 1rem;
  display: flex;
  justify-content: center;
}

.currentMonth_label {
  position: relative;
  text-transform: capitalize;
}

.navigation {
  top: 0px;
  height: 20px;
  width: 20px;

  align-items: center;
  background: none;
  display: flex;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  position: absolute;
  padding: 0;
  border: none;
  z-index: 1;
  text-indent: -999em;
  overflow: hidden;
}

.__prev {
  left: 10px;
}

.__next {
  right: 10px;
}

.navigation::after {
  border-style: solid;
  border-width: 1px 1px 0 0;
  content: '';
  display: inline-block;
  height: 10px;
  width: 10px;
  left: 5px;
  top: 5px;
  position: absolute;
  color: black;
  transform: rotate(-135deg);
  vertical-align: top;
}

.navigation.__next:after {
  transform: rotate(45deg);
}

.spinner {
  display: none;
  position: absolute;
  right: -30px;
  top: 1px;
}
