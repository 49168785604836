.container {
    width: 100%;
    height: 3.5rem;
    display: flex;
    align-items: center;
    background-color: var(--bg);
    border-radius: 8px;
    padding: 0.875rem;
    position: relative;
    border: solid 1px transparent;
}

.containerActive {
    border: solid 1px var(--right);
}

.container:hover, .container:active {
    border: solid 1px var(--right);
}

.iconContainer {
    width: 1.75rem;
    height: 1.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--text);
}

.inputContainer {
    height: 100%;
    position: relative;
    flex: 1;
}

.label {
    font-weight: 500;
    position: absolute;
    padding-left: 1rem;
    color: var(--right);
    pointer-events: none;
    left: 0;
    top: -0.4625rem;
    font-size: 0.8rem;
}

.invalidAlert {
    margin: 0;
    font-size: 0.6rem;
    color: var(--wrong);
    position: absolute;
    bottom: -2rem;
}

.selector {
    height: 100%;
    width: 100%;
}

.input {
    display: flex;
    width: 100%;
    height: 100%;
    font-size: 0.8rem;
    padding-left: 1rem;
    color: var(--text);
    background-color: transparent;
    border: none;
    outline: none;
    transition: padding-top 0.15s cubic-bezier(0.17, 0.04, 0.03, 1);
}

.inputWithLabel {
    padding-top: 0.8rem;
}
