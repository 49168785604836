.container {
  width: 100%;
  height: 3.5rem;
  display: flex;
  align-items: center;
  background-color: var(--bg);
  border-radius: 8px;
  padding: 0.875rem;
  position: relative;
}

.iconContainer {
  width: 1.75rem;
  height: 1.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--text);
}

.inputContainer {
  height: 100%;
  position: relative;
  flex: 1;
}

.label {
  font-size: 0.6rem;
  font-weight: 500;
  position: absolute;
  padding-left: 1.125rem;
  color: var(--right);
  pointer-events: none;
  left: 0;
  top: 0.3125rem;
  transition: top 0.15s cubic-bezier(0.17, 0.04, 0.03, 1), font-size 0.15s cubic-bezier(0.17, 0.04, 0.03, 1);
}

@media only screen and (max-width: 400px) {
  .label {
    font-size: 0.5rem;
  }

  .upLabel {
    top: -0.5625rem !important;
    font-size: 0.5rem !important;
  }

  .inputHover:focus + label,
  .inputHover:hover + label,
  .inputHover:active + label {
    top: -0.5625rem !important;
    font-size: 0.5rem !important;
  }

}

@media only screen and (min-width: 600px) {
  .label {
    font-size: 0.875rem;
  }
}

.upLabel {
  top: -0.5625rem !important;
  font-size: 0.75rem;
}

.invalidAlert {
  margin: 0;
  font-size: 0.75rem;
  color: var(--wrong);
  position: absolute;
  bottom: -2rem;
}

.selector {
  height: 100%;
  width: 100%;
}

.input {
  width: 100%;
  height: 100%;
  font-size: 0.875rem;
  padding-left: 1.125rem;
  color: #0f191b;
  background-color: transparent;
  border: none;
  outline: none;
  transition: padding-top 0.15s cubic-bezier(0.17, 0.04, 0.03, 1);
}

.upInput {
  padding-top: 0.625rem;
}

.inputHover:focus,
.inputHover:hover,
.inputHover:active {
  padding-top: 0.625rem;
}

.inputHover:focus + label,
.inputHover:hover + label,
.inputHover:active + label {
  top: -0.5625rem !important;
  font-size: 0.75rem;
}

.input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.input[type='number'] {
  -moz-appearance: textfield;
  appearance: textfield;
  margin: 0;
}
