.container {
  width: 100%;
  height: 100%;
  overflow: auto;
  background: white;
  position: relative;
  display: flex;
  flex-direction: column;
}

.tableContainer {
  overflow: auto;
  height: 100%;
}

.header {
  display: grid;
  /* grid-template-columns: ${({ columns }) => columns}; */
  column-gap: 5px;
  min-height: 45px;
}

.row {
  display: grid;
  /* grid-template-columns: ${({ columns }) => columns}; */
  column-gap: 5px;
  overflow-y: visible;
  padding: 5px 0;
  min-height: 45px;
}
