.container {
  position: fixed;
  bottom: 3rem;
  right: 3rem;
}
.container > a {
    text-decoration: none;
}

.button {
    display: flex;
    padding: 15px;
    justify-content: center;
    align-items: center;
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
    background: var(--primary-color);
    color: var(--font-color);
    transition: width 150ms, border-radius 150ms;
    transition-timing-function: ease-in;
    cursor: pointer;
    box-shadow: -1px 4px 18px 5px var(--shadow);
      -webkit-box-shadow: -1px 4px 18px 5px var(--shadow);
      -moz-box-shadow: -1px 4px 18px 5px var(--shadow);
}

.button:hover {
  background: var(--hover-color);
  color: var(--hover-font);
  transition-timing-function: ease-out;
  justify-content: flex-start;
  width: auto;
  border-radius: 10px;
}

.button:hover > div {
    display: block;
  }

.button > div {
  display: none;
}

.child {
  color: var(--font-color);
  overflow: hidden;
  white-space: nowrap;
}

.a {
  fill:var(--font-color);
  opacity:0;
}

.b {
  fill:none;
  stroke:var(--font-color);
  stroke-linecap:round;
  stroke-linejoin:round;
  stroke-width:1.5px;
}
