.container {
    width: 100%;
    min-width: 240px;
    display: flex;
    align-items: flex-start;
    background-color: var(--bg);
    border-radius: 8px;
    padding: 6px 12px;
    position: relative;
}

.iconContainer {
    width: 22px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    color: var(--text);
}

.inputContainer {
    height: 100%;
    position: relative;
    flex: 1;
}

.label {
    font-size: 0.8rem;
    font-weight: 500;
    position: absolute;
    padding-left: 1rem;
    color: var(--right);
    pointer-events: none;
    left: 0px;
    top: 0px;
    transition: top 0.15s cubic-bezier(0.17, 0.04, 0.03, 1), font-size 0.15s cubic-bezier(0.17, 0.04, 0.03, 1);
}

.upLabel {
    top: 0px !important;
    font-size: 10px !important;
}

.invalidAlert {
    margin: 0;
    font-size: 0.75rem;
    color: var(--wrong);
    position: absolute;
    bottom: -2rem;
}

.area {
    width: 100%;
    height: 100%;
    font-size: 0.8rem;
    padding-left: 1rem;
    color: #0f191b;
    background-color: transparent;
    border: none;
    outline: none;
    min-height: 2rem;
    overflow: auto;
    word-wrap: break-word;
    resize: none;
    transition: padding-top 0.15s cubic-bezier(0.17, 0.04, 0.03, 1);
}

.upArea {
    padding-top: 15px;
}

.areaHover:focus,
.areaHover:hover,
.areaHover:active {
    padding-top: 15px;
}

.areaHover:focus + label,
.areaHover:hover + label,
.areaHover:active + label {
    top: 0px !important;
    font-size: 0.6rem !important;
}

.area[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.area[type='number'] {
    -moz-appearance: textfield;
    appearance: textfield;
    margin: 0;
}