.modalContainer {
    position: fixed;
    inset: 0px;
    background: rgba(0, 0, 0, 0.8);
    z-index: 99999;
    transition: opacity 1s ease-in 0s;
    pointer-events: auto;
    overflow-y: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: inherit;
}

.modalInner { 
  width: 100%;
  position: relative;
  margin: 0;
  padding: 0;
  background: rgb(255, 255, 255);
  overflow: visible;
  border-radius: 24px;
  max-width: 486px;
  max-height: 75%;
  display: flex;
  flex-direction: column;
}

.modalInnerResponsive {
  width: auto;
  position: relative;
  margin: 0;
  padding: 0;
  background: rgb(255, 255, 255);
  overflow: visible;
  border-radius: 24px;
  height: auto;
  max-width: 95%;
  max-height: 95%;
  display: flex;
  flex-direction: column;
}

.headerContainer {
    padding: 16px;
    color: var(--text);
    border-radius: 8px 8px 0px 0px;
    display: flex;
    justify-content: center;
    font-weight: 700;
}

.content {
    padding: 20px 10px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

@media only screen and (min-width: 600px) {
    .content {
        padding: 30px 25px;
    }
}

.content > h3 {
    text-align: center;
    font-size: 25px;
}

.content p {
    text-align: center;
}

.mainContent {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin: 15px 0px 20px 0px;
}

.closeIcon {
    position: absolute;
    right: 6px;
    top: -27px;
    width: 20px;
    cursor: pointer;
    z-index: 100;
}
