.selector__control {
    background-color: var(--bg) !important;
    outline: none !important;
    border: none !important;
    box-shadow: none;
    min-height: unset!important;
    box-shadow: 0 0 0 1px transparent !important;
}
.selector__control:focus {
    outline: none !important;
    border: none !important;
    box-shadow: 0 0 0 1px transparent !important;
}
.selector__control:hover {
    outline: none !important;
    border: none !important;
    box-shadow: 0 0 0 1px transparent !important;
}

.selector__value-container {
    padding: 0!important;
}
.selector__single-value {
    margin: 0!important;
}
.selector__placeholder {
    padding: 0!important;
}
.selector__input-container {
    margin: 0!important;
    padding: 0!important;
}
.selector__indicators {
    display: none !important;
}

.selector__menu {
    position: absolute;
}
