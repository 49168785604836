.mainContainer {
  width: 100%;
}

.calendarContainer {
  background-color: var(--bg);
  padding: 5px;
  max-width: 100%;

  position: relative;
}

.calendarContainer::after {
  position: absolute;
  top: -12px;
  left: 0px;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-top: 12px solid var(--bg);
  transform: rotateX(-180deg);
  clear: both;
}

.invalidAlert {
  margin: 0;
  font-size: 0.75rem;
  color: var(--wrong);
  padding: 5px 10px 0px 10px;
}

.calendar {
  width: 100%;
  max-width: 330px;
}

.calendar .react-datepicker {
  max-width: 300px;
}
